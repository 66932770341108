import React from 'react';
import { Wrapper, Background } from './PageDivider.styled';
import NextImage from 'next/image';

import WavePNG from 'Public/graphics/waves/divider/divider-wave.svg';

export interface Props {
    wave?: 'default' | 'xflipped' | 'yflipped';
    backgroundColor?: React.CSSProperties['backgroundColor'];
    marginTop?: React.CSSProperties['marginTop'];
    marginBottom?: React.CSSProperties['marginBottom'];
}

const PageDivider: React.FC<Props> = ({
    backgroundColor,
    wave = 'default',
    marginTop,
    marginBottom
}) => {
    return (
        <Wrapper
            style={
                {
                    '--bg-color': backgroundColor,
                    '--margin-top': marginTop,
                    '--margin-bottom': marginBottom
                } as React.CSSProperties
            }
        >
            <Background $wave={wave}>
                <NextImage src={WavePNG} alt="" fill style={{ objectFit: 'cover' }} />
            </Background>
        </Wrapper>
    );
};

export default PageDivider;
